"use strict";

console.log('I am not a penguin');
var date = new Date();
//footDate.innerHTML=date.getFullYear();
document.querySelector('.date1').innerHTML = date.getFullYear();
//console.log(gsap.version);
//import ScrollTrigger from 'gsap/ScrollTrigger.js';
//import gsap from 'gsap';
// les lib : 
//-Chart.js https://www.chartjs.org/docs/latest/
//-apexchart https://apexcharts.com/ 

// configuration du scrolltrigger 
gsap.registerPlugin(ScrollTrigger);

var box1 = document.querySelector(".box1");
var box2 = document.querySelector(".box2");
var box3 = document.querySelector(".box3");
var i = 0;
const box = [box1,box2, box3 ];

if (box[i]== box1){
 console.log("hello");
}


ScrollTrigger.create({
  trigger: box1,
  start: "top top",
  //endTrigger:"#boxend"+i,
  end: "bottom bottom+300px",
  toggleClass: {
    targets: ".para1", className:"active"
  },
  onEnter:()=>console.log("enter"),
  onLeave:()=>console.log("leave"),
  markers:false
});

ScrollTrigger.create({
  trigger: box2,
  start: "top top",
  //endTrigger:"#boxend"+i,
  end: "bottom bottom+300px",
  toggleClass: {
    targets: ".para2", className:"active"
  },
  onEnter:()=>console.log("enter2"),
  onLeave:()=>console.log("leave2"),
  markers:false
});

ScrollTrigger.create({
  trigger: box3,
  start: "top top",
  //endTrigger:"#boxend"+i,
  end: "bottom bottom+300px",
  toggleClass: {
    targets: ".para3", className:"active"
  },
  onEnter:()=>console.log("enter3"),
  onLeave:()=>console.log("leave3"),
  markers:false
});